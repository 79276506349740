import { tns } from "tiny-slider";

const topVideosSlider = () => {
  const sliderElement: HTMLElement =
    document.getElementById("top-videos-slider");

  if (sliderElement) {
    tns({
      container: sliderElement,
      loop: true,
      autoWidth: false,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      touch: true,
      rewind: false,
      nav: false,
      mouseDrag: true,
      controls: true,
      controlsText: ["<i class='prev fa fa-caret-left'></i>", "<i class='next fa fa-caret-right'></i>"],
      responsive: {
        0: {
          items: 1,
          autoplayTimeout: 6000,
        },
        800: {
          items: 2,
        },
        1100: {
          items: 3,
        },
        1400: {
          items: 4,
        },
        1800: {
          items: 5,
        },
      },
    });
  }
};

const topCategoriesSlider = () => {
  const sliderElement: HTMLElement =
      document.getElementById("top-categories-carousel");

  if (sliderElement) {
    tns({
      container: sliderElement,
      loop: true,
      autoWidth: false,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true,
      touch: true,
      rewind: false,
      nav: false,
      mouseDrag: true,
      controls: true,
      controlsText: ["<i class='prev fa fa-caret-left'></i>", "<i class='next fa fa-caret-right'></i>"],
      responsive: {
        0: {
          items: 1,
          autoplayTimeout: 6000,
        },
        800: {
          items: 2,
        },
        1100: {
          items: 3,
        },
        1400: {
          items: 4,
        },
        1800: {
          items: 5,
        },
      },
    });
  }
};

export { topVideosSlider, topCategoriesSlider };
