import { PlayerHandler } from "../libs/Player";

export class VideoDetailEmbedPage {
  public async start(): Promise<any> {
    const player = document.querySelector("[data-player-get-settings-url]");
    if (player) {
      await new PlayerHandler(player as HTMLElement).init();
    }
  }
}
