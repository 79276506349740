const caughtPromiseAll = async (values: Promise<any>[]) => {
  return await Promise.all(
    values.map((promise) => {
      return new Promise((resolve, reject) => {
        promise.then(
          (value: any) => {
            resolve(value);
          },
          (reason) => {
            console.error(reason);
            resolve(null);
          }
        );
      });
    })
  );
};

export { caughtPromiseAll };
