import { Page } from "./Page";
import { SortElements } from "../libs/SortElements";
import { FilterElements } from "../libs/FilterElements";
import { topVideosSlider } from "../libs/sliders";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";

export class AllAmateursPage extends Page {
  public async start(): Promise<void> {
    await super.start();

    document
      .getElementById("seo-top-description")
      ?.querySelectorAll("[data-seo-text-show]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", () => {
          toggleClass(el);
        });
      });

    new SortElements().sort();

    const filterInput: HTMLInputElement = document.querySelector(
      "[data-filter-input]"
    );
    if (filterInput) {
      new FilterElements(filterInput).filter();
    }

    topVideosSlider();
  }
}
