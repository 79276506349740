import Cookies from "js-cookie";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";

interface ResultInterface {
  success: boolean;
  mode: "add" | "remove" | "login";
  message: string;
}

export class Favorites {
  constructor(private element: HTMLElement) {}

  public async init() {
    const favoriteUrl: string | undefined = this.element.dataset.favoriteGetUrl;
    if (!favoriteUrl) return;

    const response = await fetch(favoriteUrl);
    if (response.ok) {
      const result: ResultInterface = await response.json();
      if (result.success) {
        this.setFavoriteStatus(result.mode, true);
        this.initEvents();
      }
    }
  }

  public async initFromPurchases(): Promise<void> {
    if (this.element.dataset.removeThisVideoFromFavorites === "1") {
      this.element.addEventListener("click", async (e: Event) => {
        e.preventDefault();
        this.setFavoriteStatus("remove", true);
        await this.changeState();
        this.element.closest("[data-video-favorite-container]")?.remove();
      });
    } else {
      await this.init();
    }
  }

  private initEvents(): void {
    this.element.addEventListener("click", async (e: Event) => {
      e.preventDefault();
      await this.changeState();
    });
  }

  private async changeState(): Promise<any> {
    const changeUrl: string | undefined =
      this.element.dataset.favoriteChangeUrl;
    if (!changeUrl) return;

    const response = await fetch(changeUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "post",
      body: JSON.stringify({ mode: this.element.getAttribute("mode") }),
    });

    if (response.ok) {
      const result: ResultInterface = await response.json();
      if (result.success) {
        if (result.mode === "login") {
          this._redirectToLogin();
        } else {
          this.setFavoriteStatus(result.mode);
        }
      }
    }
  }

  private setFavoriteStatus(
    newStatus: "add" | "remove" | "login",
    init = false
  ): void {
    if (newStatus === "login") return;

    this.element.setAttribute("mode", newStatus);

    if ((init && newStatus === "remove") || !init) toggleClass(this.element);

    const spanTextElement: HTMLElement = this.element.querySelector("span");
    if (newStatus === "remove") {
      spanTextElement.innerText = this.element.dataset.favoriteAddedMessage;
    } else if (newStatus === "add") {
      spanTextElement.innerText = this.element.dataset.favoriteRemovedMessage;
    }
  }

  protected _redirectToLogin(): void {
    const loginLink: HTMLAnchorElement =
      document.querySelector("[data-login-link]");
    if (loginLink) {
      window.location.href = `${loginLink.href}?next=${loginLink.dataset.nextUrl}`;
    }
  }
}
