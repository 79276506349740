import { response } from "express";
import { sleep } from "@tawenda-npm/tawenda-utils";

interface JsonSiblings {
  success: boolean;
  html: string;
  next_url: string;
}

export class VideoSiblings {
  private alreadyClicked = false;

  private url: string;
  private container: HTMLElement;
  private buttonTextElement: HTMLElement;

  private loadingText: string;
  private errorText: string;
  private currentText: string;

  constructor(private button: HTMLElement) {
    this.button.addEventListener("click", async (e: Event) => {
      e.preventDefault();
      if (this.alreadyClicked) return;

      this.url = this.button.dataset.loadMoreVideos;
      this.container = this.button
        .closest("[data-video-siblings-container]")
        .querySelector("[data-video-siblings]");
      this.buttonTextElement = this.button.querySelector("span");

      this.loadingText = this.button.dataset.loadingText;
      this.errorText = this.button.dataset.errorText;
      this.currentText = this.buttonTextElement.textContent;

      await this.load();
    });
  }

  protected async load(): Promise<void> {
    this.buttonTextElement.innerText = this.loadingText;
    this.alreadyClicked = true;

    const req = await fetch(this.url);
    if (req.ok) {
      const response: JsonSiblings = await req.json();
      if (response.success) {
        this.setHTML(response);
      }
    } else {
      await this.setError();
    }

    this.alreadyClicked = false;
  }

  protected setHTML(data): void {
    this.container.insertAdjacentHTML("beforeend", data.html);
    this.button.dataset.loadMoreVideos = data.next_url;
    this.buttonTextElement.innerText = this.currentText;
  }

  protected async setError(): Promise<void> {
    this.buttonTextElement.innerText = this.errorText;
    await sleep(1500);
    this.buttonTextElement.innerText = this.currentText;
  }
}
