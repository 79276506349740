import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { sleep, switchClasses, toggleClass } from "@tawenda-npm/tawenda-utils";

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOUR = 60 * MINUTES;
const DAY = 24 * HOUR;

interface VideoInterface {
  id: number;
  score: number;
  votes: number;
  likeUrl: string;
}

interface localStorageLikeItem {
  pk: number;
  datetime: number;
}

export class Likes {
  video: VideoInterface | undefined;

  storageKey = "vl";
  dataJSON: any;

  canLike = true;

  constructor(private likeElement: HTMLElement) {
    this.dataJSON = {};

    if (
      likeElement &&
      likeElement.dataset.videoPk &&
      likeElement.dataset.likeScore &&
      likeElement.dataset.likeCount &&
      likeElement.dataset.likeUrl
    ) {
      this.video = {
        id: parseInt(likeElement.dataset.videoPk),
        score: parseInt(likeElement.dataset.likeScore),
        votes: parseInt(likeElement.dataset.likeCount),
        likeUrl: likeElement.dataset.likeUrl,
      };
    }

    try {
      this.initFromLocalStorage();
    } catch (err) {
      console.debug(err);
    }
  }

  private initFromLocalStorage(): void {
    if (!this.video) return;

    const storageValue: string | null = localStorage.getItem(this.storageKey);

    if (storageValue) {
      this.dataJSON = this.cleanOldLikesInStorage(
        Likes.decodeValue(storageValue)
      );
    }

    if (this.dataJSON[this.video.id]) {
      this.liked(false);
    }
  }

  public async like(): Promise<void> {
    if (!this.video || !this.canLike) return;

    const response = await fetch(this.video.likeUrl, {
      headers: { "X-CSRFToken": Cookies.get("csrftoken") || "" },
      method: "post",
      body: JSON.stringify({
        score: this.video.score,
        pk: this.video.id,
      }),
    });

    return response.ok ? this.liked() : this.alreadyLiked();
  }

  private liked(update_counter = true): void {
    if (!this.video) return;

    if (update_counter) ++this.video.votes;

    const likeCounter: HTMLSpanElement = this.likeElement.querySelector(
      "span[data-nb-likes]"
    );
    if (likeCounter) {
      likeCounter.innerText = this.video.votes.toString();
    }

    switchClasses(this.likeElement);

    if (localStorage) {
      this.dataJSON[this.video.id] = Date.now();
      localStorage.setItem(this.storageKey, Likes.encodeValue(this.dataJSON));
    }
  }

  private async alreadyLiked(): Promise<void> {
    if (!this.video) return;

    this.canLike = false;

    toggleClass(this.likeElement);
    await sleep(2000);
    toggleClass(this.likeElement);

    this.canLike = true;
  }

  private static encodeValue(value: JSON): string {
    return Base64.encode(JSON.stringify(value));
  }

  private static decodeValue(value: string): Array<localStorageLikeItem> {
    return JSON.parse(Base64.decode(value));
  }

  private cleanOldLikesInStorage(
    data: Array<localStorageLikeItem>
  ): Array<localStorageLikeItem> {
    Object.entries(data).forEach((item: Array<any>) => {
      if (item[1] <= Date.now() - DAY * 10) {
        delete data[item[0]];
      }
    });
    return data;
  }
}
