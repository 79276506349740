import { HomePage } from "./HomePage";
import { VideosListPage } from "./VideosList";
import { Page } from "./Page";
import { ContactPage } from "./ContactPage";
import { VideoDetailPage } from "./VideoDetailPage";
import { UserRegisterPage } from "./UserRegisterPage";
import { UserEditPage } from "./UserEditPage";
import { HowToDisableAdBlockPage } from "./AdBlockHowToDisable";
import { CheckDevicePage } from "./CheckDevicePage";
import { AllCategoriesPage } from "./AllCategoriesPage";
import { UserFavoritesPage } from "./UserFavoritesPage";
import { AllAmateursPage } from "./AllAmateurs";
import { VideoDetailEmbedPage } from "./VideoDetailEmbedPage";

const initPages = (): void => {
  const body = document.querySelector("body");
  let page;

  switch (body.getAttribute("data-page")) {
    case "home":
      page = new HomePage();
      break;

    case "videos-list": {
      page = new VideosListPage();
      break;
    }

    case "video-detail": {
      page = new VideoDetailPage();
      break;
    }

    case "video-detail-embed": {
      page = new VideoDetailEmbedPage();
      break;
    }

    case "all-categories": {
      page = new AllCategoriesPage();
      break;
    }

    case "all-amateurs": {
      page = new AllAmateursPage();
      break;
    }

    case "user-register": {
      page = new UserRegisterPage();
      break;
    }

    case "user-edit": {
      page = new UserEditPage();
      break;
    }

    case "user-favorites": {
      page = new UserFavoritesPage();
      break;
    }
    case "how-to-disable-adblock": {
      page = new HowToDisableAdBlockPage();
      break;
    }

    case "check-device": {
      page = new CheckDevicePage();
      break;
    }

    case "contact": {
      page = new ContactPage();
      break;
    }
  }

  if (!page) {
    page = new Page();
  }
  page.start();
};

export default initPages;
