import { Page } from "./Page";
import { PlayerHandler } from "../libs/Player";
import { Likes } from "../libs/Likes";
import { Favorites } from "../libs/Favorites";
import { topVideosSlider } from "../libs/sliders";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";
import { VideoSiblings } from "../libs/VideoSiblings";

export class VideoDetailPage extends Page {
  public async beforeStart(): Promise<void> {
    const partiallyDisabledPlayer: HTMLElement = document.querySelector(
      "#video-player.partially-disabled"
    );

    const player: HTMLElement = document.querySelector(
      "[data-player-get-settings-url]"
    );
    if (player) {
      const redirectTargetUrl = player.dataset.alternateVideoUrl;
      await new PlayerHandler(player as HTMLElement).init();
      if (redirectTargetUrl) {
        player.addEventListener("ended", () => {
          window.location.href = redirectTargetUrl;
        });
        setTimeout(() => {
          window.location.href = redirectTargetUrl;
        }, 10000);
      }
    }
  }

  public async start(): Promise<any> {
    await this.beforeStart();
    await super.start();

    topVideosSlider();

    document
      .getElementById("seo-video-description")
      ?.querySelectorAll("[data-seo-text-show]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", () => {
          toggleClass(el);
        });
      });

    const my18passSelector = document.querySelector(
      "[data-my18pass-free-video]"
    );
    my18passSelector?.addEventListener("click", (e: Event) => {
      (document.querySelector("my18pass-widget") as any).openDialog(e);
    });

    const likeElement: HTMLElement = document.querySelector(
      "[data-like-container]"
    );
    if (likeElement) {
      const likeHandler = new Likes(likeElement);

      likeElement.addEventListener("click", async (e: Event) => {
        e.preventDefault();
        await likeHandler.like();
      });
    }

    const favoriteElement: HTMLElement = document.querySelector(
      "[data-favorite-get-url]"
    );
    if (favoriteElement) {
      await new Favorites(favoriteElement).init();
    }

    const loadSiblingsButton: HTMLElement = document.querySelector(
      "[data-load-more-videos]"
    );
    if (loadSiblingsButton) {
      new VideoSiblings(loadSiblingsButton);
    }
  }
}
