import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";

export class filters {
  private wrapper: HTMLElement = document.getElementById("filters-container");

  private discountButton: HTMLElement = document.getElementById(
    "bloc-filter-discount_videos"
  );
  private checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    "[data-filter-checkbox]"
  );

  private tagSearchInput: HTMLInputElement = document.querySelector(
    "[data-filter-tag-search]"
  );
  private allTags: Array<HTMLElement> = [];
  private allTagsPks: Array<number> = [];

  constructor(private handler: HTMLElement) {
    if (this.wrapper) {
      handler.addEventListener("click", () => {
        toggleClass(this.wrapper);
      });
      this.initEvents();
    }
  }

  private initEvents(): void {
    this.setupDiscount();
    this.setupCheckboxes();
    this.setupSearchFilter();
  }

  private setupDiscount(): void {
    this.discountButton?.addEventListener("click", (e: Event) => {
      e.preventDefault();
      this._redirect(this.discountButton.closest("a").href);
    });
  }

  private setupSearchFilter(): void {
    document
      .querySelectorAll("[data-filter-tag-item]")
      .forEach((el: HTMLElement) => {
        this.allTags.push(el);
      });

    this.allTagsPks = this.allTags.map((el: HTMLElement) => {
      return parseInt(el.dataset.filterTagItem);
    });

    this.tagSearchInput?.addEventListener("input", () => {
      const searchValue = this.tagSearchInput.value;

      if (searchValue === "") {
        this._resetTags();
      } else {
        this._filterTags(searchValue);
      }
    });
  }

  private setupCheckboxes(): void {
    this.checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.addEventListener("click", (event: Event) => {
        this._redirect(checkbox.dataset.url);
      });
    });
  }

  protected _resetTags(): void {
    document
      .querySelectorAll("[data-filter-tag-item]")
      .forEach((el: HTMLElement) => {
        el.style.display = "flex";
      });
    document
      .querySelectorAll("[data-filter-tag-container]")
      .forEach((el: HTMLElement) => {
        el.style.display = "grid";
      });
  }

  protected _filterTags(searchValue: string): void {
    const tags_to_keep_pks = this.allTags
      .filter((el) => {
        return (
          el.dataset.filterTagName
            .toString()
            .toLowerCase()
            .indexOf(searchValue) > -1
        );
      })
      .map((el) => {
        return el.dataset.filterTagItem;
      });

    this.allTagsPks.forEach((pk: number) => {
      if (tags_to_keep_pks.indexOf(pk.toString()) < 0) {
        (
          document.querySelector(
            '[data-filter-tag-item="' + pk + '"]'
          ) as HTMLElement
        ).style.display = "none";
      } else {
        (
          document.querySelector(
            '[data-filter-tag-item="' + pk + '"]'
          ) as HTMLElement
        ).style.display = "flex";
      }
    });

    document
      .querySelectorAll("[data-filter-tag-container]")
      .forEach((container: HTMLElement) => {
        const dds = container.querySelectorAll("dd"),
          visible_items = Array.prototype.filter.call(dds, function (el) {
            return el.style.display !== "none";
          });
        if (visible_items.length > 0) {
          container.style.display = "grid";
        } else {
          container.style.display = "none";
        }
      });
  }

  protected _redirect(url: string): void {
    window.location.href = url;
  }
}
