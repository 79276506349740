import { Page } from "./Page";
import { topCategoriesSlider, topVideosSlider } from "../libs/sliders";

export class HomePage extends Page {
  public async start(): Promise<void> {
    await super.start();

    topCategoriesSlider();
    topVideosSlider();
  }
}
