import { Disclaimer } from "@tawenda-npm/tawenda-disclaimer";
import "lazysizes";
import { AdBlock } from "@tawenda-npm/tawenda-adblock";
import { PlansQ } from "@tawenda-npm/tawenda-plansq";
import { Popunder } from "@tawenda-npm/tawenda-popunder";
import { UserState } from "../libs/UserState";
import lazyFrame from "../libs/lazyFrame";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";
import initBackButton from "@tawenda-npm/tawenda-back-button";
import { caughtPromiseAll } from "../libs/promises";
import { Menu } from "../libs/Menu";
import initNextUrls from "../libs/nextUrl";
import initSearch from "../libs/Search";
import DalenysSubscription from "../libs/DalenysSubscription";

import { initializeFaro } from "@grafana/faro-web-sdk";

export class Page {
  protected readonly disclaimer: Disclaimer;
  protected readonly popunder: Popunder;
  protected readonly menu: Menu;
  protected readonly dalenysModal: HTMLElement | undefined;
  protected readonly dalenysBanner: HTMLElement | undefined;
  protected readonly disablePopunders: boolean;

  constructor() {
    const faro = initializeFaro({
      url: "https://faro.desinhibition.com/collect",
      apiKey: "eHzsj9KV6QShr7t5uwf3GR",
      app: {
        name: "desinhibition",
        version: document.documentElement.dataset.appVersion,
      },
    });

    this.disablePopunders =
      document.documentElement.dataset.disablePopunder === "true";
    this.menu = new Menu();
    this.popunder = new Popunder(document.documentElement.dataset.popunderUrl);
    this.disclaimer = new Disclaimer(this.disablePopunders);
    if (!this.disablePopunders) {
      this.disclaimer.addEventListener("closed", async () => {
        await this.popunder.redirectToPopUnder();
      });
    }

    this.dalenysModal = document.querySelector("[data-dalenys-modal]");
    this.dalenysBanner = document.querySelector("[data-dalenys-banner]");
  }

  public async start(): Promise<void> {
    const promisesToCall = [
      this.disclaimer.loadDisclaimer(),
      this.disablePopunders ? Promise.resolve() : this.popunder.setupDOM(),
      new AdBlock().showAegis(),
      new UserState(
        (
          document.querySelector("[data-user-state-url]") as HTMLElement
        ).dataset.userStateUrl
      ).setupDOM(),
    ];

    await caughtPromiseAll(promisesToCall);

    lazyFrame();
    initBackButton("desinhibition");
    initNextUrls();
    if (this.dalenysModal || this.dalenysBanner) {
      new DalenysSubscription(this.dalenysBanner ?? this.dalenysModal).show();
    }

    document
      .querySelectorAll("[data-search]")
      .forEach((searchForm: HTMLFormElement) => {
        initSearch(searchForm);
      });

    document
      .getElementById("seo-bottom-description")
      ?.querySelectorAll("[data-seo-text-show]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", () => {
          toggleClass(el);
        });
      });

    const plansQDesktop: HTMLElement = document.querySelector(
      "[data-plans-q-desktop]"
    );
    if (plansQDesktop) new PlansQ(plansQDesktop).show();

    const plansQMobile: HTMLElement = document.querySelector(
      "[data-plans-q-mobile]"
    );
    if (plansQMobile) new PlansQ(plansQMobile).show();
  }
}
