import Cookies from "js-cookie";

export class TrailerAd {
  bodyElement: HTMLBodyElement;
  player: any;
  readonly cookieName = "trlr_ad";
  readonly cookieNameIgnore = "trlr_ad_ignore";
  modalElement: HTMLDivElement | null;
  ajaxUrl: string | undefined;

  constructor(player: any) {
    this.bodyElement = document.getElementsByTagName("body")[0];
    this.player = player;
    this.modalElement = null;
    this.ajaxUrl = this.bodyElement.dataset.trailerAdUrl;
  }

  public check(video_id: number): void {
    if (this.isIgnored()) return;

    if (!this.cookieExists()) {
      TrailerAd.setCookie(
        this.cookieName,
        JSON.stringify({
          pks: [video_id],
        }),
        2
      );
    } else if (this.shouldShowModal(video_id)) {
      this.showModal().catch(() => undefined);
    } else {
      this.updateCookie(video_id);
    }
  }

  private isIgnored(): boolean {
    return Cookies.get(this.cookieNameIgnore) !== undefined;
  }

  private cookieExists(): boolean {
    return Cookies.get(this.cookieName) !== undefined;
  }

  private getCookie(): string | undefined {
    return Cookies.get(this.cookieName);
  }

  private static setCookie(name: string, value: string, expires = 1): void {
    Cookies.set(name, value, { path: "/", expires: expires });
  }

  private updateCookie(video_id: number): boolean {
    const cookie = this.getCookie();
    if (!cookie) return false;

    const cookieValue = JSON.parse(cookie);

    if (cookieValue === undefined || cookieValue.pks.indexOf(video_id) >= 0) {
      return false;
    } else {
      const pks = cookieValue.pks;
      if (pks.indexOf(video_id) < 0) {
        pks.push(video_id);
        TrailerAd.setCookie(this.cookieName, JSON.stringify({ pks: pks }));
      }
    }
    return true;
  }

  private setIgnoreCookie(): void {
    TrailerAd.setCookie(this.cookieNameIgnore, "", 2);
    this.deleteCookie();
  }

  private deleteCookie(): void {
    Cookies.remove(this.cookieName);
  }

  private shouldShowModal(video_id: number): boolean {
    const cookie = this.getCookie();
    if (cookie) {
      const pks = JSON.parse(cookie).pks;
      return pks.length >= 2 && pks.indexOf(video_id) < 0;
    }

    return false;
  }

  private async showModal(): Promise<any> {
    if (!this.ajaxUrl) return;

    const response = await fetch(this.ajaxUrl);

    if (response.ok) {
      this.bodyElement.insertAdjacentHTML("afterbegin", await response.text());

      this.player.pause();
      this.setIgnoreCookie();

      this.modalElement = document.querySelector("[data-modal-trailer-ad]");
      if (!this.modalElement) return;

      this.modalElement.style.display = "block";

      const adIgnoreHandler = this.modalElement.querySelector(
        "[data-trailer-ad-ignore]"
      );
      if (adIgnoreHandler) {
        adIgnoreHandler.addEventListener("click", () => {
          this.removeModal();
        });
      }
    }
  }

  private removeModal() {
    this.modalElement?.remove();
    this.setIgnoreCookie();
  }
}
