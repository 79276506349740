import { toggleClass, toggleText } from "@tawenda-npm/tawenda-utils";
import { tns } from "tiny-slider";
import { Page } from "./Page";
import { filters } from "../libs/filters";
import { topCategoriesSlider, topVideosSlider } from "../libs/sliders";

export class VideosListPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    document
      .getElementById("seo-videos-list-intro")
      ?.querySelectorAll("[data-seo-text-show]")
      .forEach((el: HTMLElement) => {
        el.addEventListener("click", () => {
          toggleClass(el);
        });
      });

    document
      .querySelectorAll("[data-filters-handler]")
      .forEach((el: HTMLElement) => {
        new filters(el);
      });

    topCategoriesSlider();
    topVideosSlider();
  }
}
