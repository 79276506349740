import { PromoWidget } from "@tawenda-npm/tawenda-promo-widget";

interface UserObject {
  success: boolean;
  is_authenticated: boolean;
  has_subscription: boolean;
}

export class UserState {
  private readonly bodyElement: HTMLBodyElement;
  private user: UserObject;

  constructor(private url: string) {
    this.bodyElement = document.getElementsByTagName("body")[0];
    this.user = {
      success: false,
      is_authenticated: false,
      has_subscription: false,
    };
  }

  public async setupDOM(): Promise<void> {
    const response = await fetch(this.url);
    if (response.ok) {
      this.user = await response.json();

      if (this.user.success) {
        if (this.user.is_authenticated) {
          this.bodyElement.classList.add("userstate--is-authenticated");
        }

        if (this.user.has_subscription) {
          this.bodyElement.classList.add("userstate--has-subscription");
        } else {
          this.setupPromoWidget();
        }
      }
    }
  }
  private setupPromoWidget(): void {
    const promoWidgetElement: HTMLElement = document.querySelector(
      "[data-promo-widget-container]"
    );
    if (promoWidgetElement) {
      new PromoWidget(promoWidgetElement).toggle();
    }
  }
}
