import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";
import { tns } from "tiny-slider/src/tiny-slider";

export class Menu {
  private readonly wrapper: HTMLElement;
  private readonly background: HTMLElement;

  constructor() {
    this.wrapper = document.querySelector("[data-menu-wrapper]");
    this.background = document.querySelector("[data-background-menu-close]");
    this.initEvents();
    this.initEventsForSearchMobile();
    this.initCarousel();
  }

  private initEvents(): void {
    document
      .querySelectorAll("[data-menu-handler]")
      .forEach((menuHandler: HTMLElement) => {
        menuHandler.addEventListener("click", (e: Event) => {
          toggleClass(this.wrapper);
        });
      });

    this.background?.addEventListener("click", (e: Event) => {
      this.wrapper.classList.remove("menu-open");
    });
  }

  private initEventsForSearchMobile(): void {
    document
      .querySelectorAll("[data-header-search-handler-mobile]")
      .forEach((searchMobileHandler: HTMLElement) => {
        searchMobileHandler.addEventListener("click", (e: Event) => {
          document
            .querySelector("[data-header-search-bar]")
            ?.classList.toggle("search-bar-open");

          document
            .querySelectorAll("[data-header-container]")
            .forEach((headerContainer: HTMLElement) => {
              headerContainer.classList.toggle("hide-menu-mobile");
            });
        });
      });

    document
      .querySelector("[data-header-search-handler-mobile]")
      .addEventListener("click", (e: Event) => {
        (
          document.querySelector(
            "[data-search] input[data-search-url]"
          ) as HTMLInputElement
        ).focus();
      });
  }

  private initCarousel(): void {
    const sliderMenu = document.querySelector("[data-header-banner-menu]");
    if (sliderMenu) {
      tns({
        container: sliderMenu,
        items: 6,
        gutter: 20,
        nav: false,
        controls:false,
        loop: false,
        prevButton:false,
        nextButton:false,
        autoWidth: true,
        center: false,
        touch: true,
        slideBy: 2,
        responsive: {
          0: {
            autoplay: true,
            items: 3,
          },
          500: {
            autoplay: true,
            items: 4,
          },
          800: {
            autoplay: true,
            items: 5,
          },
          1000: {
            autoplay: true,
            items: 6,
          },
          1300: {
            items: 6,
          },
        },
      });
    }
  }
}
