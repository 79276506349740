const initNextUrls = () => {
  document
    .querySelectorAll("[data-next-url]")
    .forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener("click", (e: Event) => {
        if ((e.target as HTMLAnchorElement).href === "undefined") {
          return anchor.closest("a").click();
        }

        (e.target as HTMLAnchorElement).href = `${
          (e.target as HTMLAnchorElement).href
        }?next=${anchor.dataset.nextUrl}`;
        return e;
      });
    });
};

export default initNextUrls;
