import { EmailValidator } from "@tawenda-npm/msa-browser";

export class UserEdit {
  constructor(private selector: string) {}

  public initValidator(): void {
    const validator = new EmailValidator({
      lng: "fr",
      form: {
        selector: this.selector,

        onError: (err: string): void => {
          const submitButton = validator.submitButton;
          if (!submitButton) return;

          submitButton.disabled = false;
          submitButton.removeAttribute("disabled");
          submitButton.innerHTML = submitButton.dataset.defaultContent;

          const ul = document.createElement("ul");
          ul.className = "errorlist";
          const li = document.createElement("li");
          li.innerText = err;
          ul.appendChild(li);

          validator.form?.querySelector("fieldset")?.append(ul);
        },

        onBeforeValidation: (): boolean => {
          const submitButton = validator.submitButton;
          if (!submitButton) return false;

          submitButton.dataset.defaultContent = submitButton.innerHTML;
          submitButton.disabled = true;
          submitButton.setAttribute("disabled", "disabled");
          submitButton.innerHTML = submitButton.dataset.loadingContent;

          const errorList = validator.form?.querySelector(".errorlist");
          if (errorList) errorList.remove();

          return true;
        },
      },
    });
  }
}
