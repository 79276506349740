import { Page } from "./Page";
import { UserEdit } from "../libs/UserEdit";

export class UserEditPage extends Page {
  public async start(): Promise<any> {
    await super.start();

    new UserEdit("section form.section__form_wrapper").initValidator();
  }
}
