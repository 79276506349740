import { TawendaFingerprintCheckDevice } from "@tawenda-npm/tawenda-fingerprint-check-device";

export class CheckDevicePage {
  public async start(): Promise<void> {
    const checkDeviceHandler: HTMLElement = document.querySelector(
      "[data-check-device-url]"
    );
    if (checkDeviceHandler) {
      const checkDevice = new TawendaFingerprintCheckDevice(
        checkDeviceHandler.dataset.checkDeviceUrl,
        checkDeviceHandler.dataset.checkDeviceNext
      );
      await checkDevice.redirectIfAllowed();
    }
  }
}
