import Cookies from "js-cookie";
import { Favorites } from "./Favorites";

export class UserFavorites {
  public init(): void {
    document
      .querySelectorAll("[data-favorite-change-url]")
      .forEach(async (el: HTMLElement) => {
        await new Favorites(el).initFromPurchases();
      });
  }
}
