const initSearch = (form: HTMLFormElement) => {
  form.addEventListener("submit", (e: Event) => {
    e.preventDefault();
    const input: HTMLInputElement = form.querySelector("[data-search-url]");
    if (input.value !== "" && input.value !== " ") {
      document.location.href = input.dataset.searchUrl.replace(
        "QUERY",
        encodeURI(input.value)
      );
    }
  });
};

export default initSearch;
